import React, { useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import parse from "html-react-parser"
import { BgImage } from "gbimage-bridge"
import "aos/dist/aos.css"

import { motion } from "framer-motion"

import yellowArrow from "../assets/arrow-yellow-project-showcase.svg"

const imageMotion = {
  rest: {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
  hover: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
}
const textMotion = {
  rest: {
    y: 0,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeIn",
    },
  },
  hover: {
    y: -25,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeOut",
    },
  },
}

const arrowMotion = {
  rest: { opacity: 0, ease: "easeOut", duration: 0.2, type: "tween" },
  hover: {
    opacity: 1,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeIn",
    },
  },
}

const ProjectShowcase = () => {
  useEffect(() => {
    const AOS = require("aos")
    AOS.init({
      duration: 700,
      offset: 75,
      delay: 75,
      once: false,
      mirror: true,
      easing: "ease",
    })

    if (AOS) {
      AOS.refresh()
    }
  }, [])

  const projects = useStaticQuery(graphql`
    query Projects {
      allWpProject(sort: { fields: portfolioOrder }) {
        nodes {
          id
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, height: 500, width: 500)
                }
              }
            }
          }
          title
          uri
          portfolioSettings {
            clientName
          }
        }
      }
    }
  `)

  let allProjects = projects.allWpProject.nodes

  return (
    <div className="columns is-multiline">
      {allProjects.map((project, i) => (
        <div
          key={project.id}
          data-aos="fade-up"
          className="column is-6-tablet is-6-desktop is-4-widescreen p-0 m-0"
          style={{ padding: "0px", margin: "0px 0px" }}
        >
          <Link to={project.uri}>
            {project.featuredImage?.node?.localFile?.childImageSharp
              ?.gatsbyImageData && (
              <BgImage
                image={
                  project.featuredImage?.node?.localFile?.childImageSharp
                    ?.gatsbyImageData
                }
                alt=""
              >
                <motion.div
                  className="ratio-square"
                  variants={imageMotion}
                  initial="rest"
                  whileHover="hover"
                  animate="rest"
                >
                  <div>
                    <motion.h2
                      variants={textMotion}
                      className="title-showcase-tile is-size-2 has-text-weight-bold is-size-2-mobile has-text-white"
                    >
                      {parse(project.title)}
                    </motion.h2>
                    <motion.div
                      className="arrow-container"
                      variants={arrowMotion}
                    >
                      <img
                        src={yellowArrow}
                        style={{ height: "20px" }}
                        alt=""
                      />
                    </motion.div>
                  </div>
                </motion.div>
              </BgImage>
            )}
          </Link>
        </div>
      ))}
    </div>
  )
}

export default ProjectShowcase
