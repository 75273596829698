import React, { useEffect } from "react"
import { graphql } from "gatsby"

/* NPM */
import "aos/dist/aos.css"
import ProjectShowcase from "../components/ProjectShowcase"

/* Indie Components */
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import MountainHeader from "../components/MountainHeader"
import PageWrapper from "../components/PageWrapper"
import PageTitleSection from "../components/PageTitleSection"
import Section from "../components/Section"
const ProjectsPageTemplate = ({ data: { page } }) => {
  let AOS

  useEffect(() => {
    const AOS = require("aos")
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })
  return (
    <Layout showFooter={page.pageSettings.showFooter}>
      <Seo seo={page.seo} />
      <MountainHeader headerImages={page.headerSettings.headerImages} />
      <PageWrapper additionalClassName="work">
        <PageTitleSection
          title={page.headerSettings.title}
          subtitle={page.headerSettings.subTitle}
        />

        <Section additionalClasses="projects has-background-black-bis">
          <ProjectShowcase></ProjectShowcase>
        </Section>
      </PageWrapper>
    </Layout>
  )
}

export default ProjectsPageTemplate

export const WorkPageQuery = graphql`
  query WorkPageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      ...pageFields
    }
  }
`
